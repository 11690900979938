<template>
  <form class="row g-3 p-3" method="GET" @submit.prevent="submit">
    <div class="col-12 text-center mb-4">
      <img class="img-fluid m-auto pb-3" width="30%" src="/images/logo3T.png" alt="" />

      <h1 class=" ">Iniciar Sesión</h1>
    </div>
    <div class="col-12">
      <div class="form-floating mb-3">
        <input required v-model="user.nombreusuario" type="email" class="form-control" id="emailInput" placeholder="Correo electrónico" />
        <label for="emailInput">Correo electrónico</label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-floating mb-3">
        <input required v-model="user.clave" type="password" class="form-control" id="passwordInput" placeholder="Contraseña" />
        <label for="passwordInput">Contraseña</label>
      </div>
      <div class="d-flex justify-content-between">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="recuerdame" v-model="recuerdame" :checked="recuerdame ? true : false" />
          <label class="form-check-label" for="recuerdame">Recuérdame</label>
        </div>
        <router-link class="text-decoration-none text-primary" to="/authentication/auth-password-reset">¿Olvidaste tu contraseña?</router-link>
      </div>
    </div>
    <div class="col-12 text-center mt-3">
      <button type="submit" class="btn btn-primary w-100 py-3">Iniciar Sesión</button>
    </div>
    <div class="col-12 text-center mt-4">
      <p class="text-muted">V 1.241108</p>
    </div>
  </form>
</template>

<script>
// import { encrypt, decrypt } from "ncrypt-js";
// import ncrypt from "ncrypt-js";
import { eventBus } from "../../../main";

export default {
  name: "Sign_inComponent",
  data() {
    return {
      recuerdame: false,
      user: {
        nombreusuario: "", //, "abr@siscoper.net",
        clave: "", //,  "Mecatestudio_9090", // "creasa2020", //"Mecatestudio_2323"  "Mecatestudio_9090",
      },
    };
  },
  mounted() {
    ////////////////////////
    try {
      // fetch("http://146.190.216.232:8069/web/session/authenticate", {
      //   method: "POST",
      //   headers: {
      //     // "Content-Type": "application/json",
      //     // Authorization: "Bearer <tu-token>",
      //     // "Access-Control-Allow-Origin": "*",
      //     token: "1e3fe320f9a2cd20b4ecbf5b43d6f95e38c3871f",
      //   },
      //   body: JSON.stringify({
      //     jsonrpc: "2.0",
      //     params: {
      //       db: "_.db",
      //       login: "_.user",
      //       password: "_.password",
      //       token: "1e3fe320f9a2cd20b4ecbf5b43d6f95e38c3871f",
      //     },
      //     token: "1e3fe320f9a2cd20b4ecbf5b43d6f95e38c3871f",
      //     id: 1,
      //   }),
      // })
      //   .then((response) => response.json())
      //   .then((data) => console.log(data))
      //   .catch((error) => console.error("Error---------->*:", error));
    } catch (error) {
      console.log("errror------------->", error);
    }

    ////////////////////////
    this.recuerdame = JSON.parse(localStorage.getItem("recuerdame"));
    if (this.recuerdame) {
      this.user = JSON.parse(localStorage.getItem("loginData"));
    }
  },
  methods: {
    showRecuerdame() {
      if (this.recuerdame) {
        localStorage.setItem("recuerdame", this.recuerdame);
        localStorage.setItem("loginData", JSON.stringify(this.user));
      } else {
        localStorage.removeItem("recuerdame");
        localStorage.removeItem("loginData");
      }
    },
    async submit(e) {
      this.$isLoading(true);
      try {
        let res = await this.$store.dispatch("login", { data: this.user });
        this.$isLoading(false);
        if (res.data) {
          this.showRecuerdame();
          let user = res.data;
          let Configuration = await this.$store.dispatch("get", { path: "meca_configuracion/get/" + user.empresa_id });
          localStorage.setItem("config", JSON.stringify(Configuration));

          let companyData = await this.$store.dispatch("get", { path: "empresas/getById/" + user.empresa_id });
          console.log("datacompany", companyData);
          //   let dataTheme = {
          //     themecolor: companyData[0].themecolor,
          //     darkMode: companyData[0].darkMode,
          //   };

          localStorage.setItem(
            "themecompany",
            JSON.stringify({
              themecolor: companyData[0].themecolor,
              darkMode: companyData[0].darkMode,
            })
          );
          const html = document.documentElement; // returns the html tag
          if (companyData[0].themecolor != null) {
            document.documentElement.removeAttribute("class");
            // document.body.className = companyData[0].themecolor;
            this.$store.commit("refreshTheme", companyData[0].themecolor);

            // document.body.className =  "theme-green" //companyData[0].themecolor;
            // var root = document.getElementsByTagName( 'body' )[0];
            // root.removeAttribute("class");
            // root.setAttribute("class", companyData[0].themecolor);
            // alert('ok')
          }
          if (companyData[0].darkMode == true || companyData[0].darkMode == 1) {
            html?.setAttribute("data-theme", "dark");
          } else {
            html?.removeAttribute("data-theme");
          }
          //   alert("L " + companyData[0].themecolor);
          //   vm.$forceUpdate();
          //or in file components
          this.$forceUpdate();
          //this.$store.commit('setToken', res.token)
          //this.$store.commit('setUser', res.data)
          // this.$router.push('/home')
          let dataCompany = {
            nombre: companyData[0].nombre,
            logo: companyData[0].picture,
          };
          localStorage.setItem("company", JSON.stringify(dataCompany));
          localStorage.setItem("company_id", user.empresa_id);
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("rol", user.rol);
          localStorage.setItem("user_id", user.id);
          localStorage.setItem("urllogo", user.url_logo);
          localStorage.setItem("sucursal_id", user.sucursal_id);
          localStorage.setItem("nombreusuario", user.nombreusuario);
          localStorage.setItem("plan", user.plan_id);
          localStorage.setItem("iva", user.iva);
          localStorage.setItem("moneda", user.moneda);
          const newUser = { uid: user.id };
          localStorage.setItem("userInfo", JSON.stringify(newUser));
          let textos = {
            type: res.account_type,
            txtin: res.account_type == "Personal" ? "Ingresos" : "Ventas",
            txtout: res.account_type == "Personal" ? "Gastos" : "Compras",
          };
          localStorage.setItem("account", JSON.stringify(textos));

          console.log("typeacc", res.account_type);

          eventBus.$emit("updatemap");
          if (user.rol == "Vendedor") {
            this.$router.push({ path: "/purchasesSales/salesDetail" });
          } else {
            this.$router.push({ path: "/dashboard/index" });
          }
          e.preventDefault();
        } else {
          this.$swal({ title: "Aviso", text: "Error: problema con credenciales.(cod:15)", icon: "error" });
        }
      } catch (error) {
        this.$isLoading(false);
        this.$swal({ title: "Aviso", text: "Error: problema con credenciales.(cod:55)", icon: "error" });
      }
    },
  },
};
</script>
<style scoped></style>
